<template>
  <div>
    <organization-section
      :organizations="groups"
      @setOrganizationPage="getOrganizations"
    />
  </div>
</template>
<script>
import { talentClient } from "@/api";
import { initialListingModel } from "@/utils";
import OrganizationSection from "@/components/organizations/OrganizationSection.vue";
export default {
  name: "GroupsPage",
  components: { OrganizationSection },
  data() {
    return {
      groups: initialListingModel(5),
    };
  },
  computed: {
    organization() {
      return this.$store.state.organizations.organization;
    },
  },
  watch: {
    organization: {
      handler(val) {
        if (val?.subsidiary_organizations?.length) {
          this.getOrganizations(this.groups.page);
        }
      },
    },
  },
  activated() {
    if (this.organization?.subsidiary_organizations?.length) {
      this.getOrganizations(this.groups.page);
    }
  },
  methods: {
    async getOrganizations(page = 1) {
      if (this.groups.pending) return;
      this.groups.error = "";
      this.groups.pending = true;
      this.groups.page = page;
      try {
        const { data } = await talentClient({
          method: "GET",
          url: "organizations/",
          params: {
            limit: this.groups.limit,
            offset: (this.groups.page - 1) * this.groups.limit,
            ids: this.organization.subsidiary_organizations.join(","),
          },
        });
        this.groups.total = data.count;
        this.groups.list = data.results;
        this.groups.pagesCount = Math.ceil(data.count / this.groups.limit);
      } catch (error) {
        this.groups.error = error.message;
      }
      this.groups.pending = false;
    },
  },
};
</script>
