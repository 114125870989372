<template>
  <info-section
    class="mb-4"
    :total="organizations.total"
    :pending="organizations.pending"
    icon="mdi-google-circles"
    title="Кружки"
    :pages="organizations.pagesCount"
    :current-page="organizations.page"
    @setPage="setPage"
  >
    <organization-card
      v-for="(org, idx) in organizations.list"
      :key="org.id"
      :organization="org"
      with-id-link
      class="item"
      :class="{ last: idx === organizations.list.length - 1 }"
    />
    <div
      v-if="
        !organizations.list.length && !organizations.pending && isOrganization
      "
    >
      Нет данных
    </div>
  </info-section>
</template>
<script>
import InfoSection from "@/components/InfoSection.vue";
import OrganizationCard from "./OrganizationCard.vue";
export default {
  name: "OrganizationSection",
  components: { InfoSection, OrganizationCard },
  props: {
    organizations: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isOrganization() {
      return !!this.$store.state.organizations.organization;
    },
  },
  methods: {
    setPage(page) {
      this.$emit("setOrganizationPage", page);
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  border-bottom: 1px solid #c0c0c0;
  padding-bottom: 10px;
  margin-bottom: 10px;
  transition: background-color 0.3s;

  &.last {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
</style>
